export default {
  "button": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "resetStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaciar la tienda"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar"])},
    "moveTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])}
  },
  "alerts": {
    "errors": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este identificador ya está en uso!"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta tarjeta ya está registrada en esta escalera!"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este código de teclado ya existe para este acceso!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este código telefónico ya existe para este acceso!"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta combinación de nombre e identificador ya existe para este apartamento!"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este número corto ya está en uso!"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este día festivo ya existe!"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta tarjeta ya se usa como una llave Pass!"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha superado los límites (Nb de fiches...) de su plan GSM !"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su número de contrato GSM no está referenciado."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su central no se actualizará\ncon este número de teléfono\npor cuestiones de tarifas!"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No tiene permiso para modificar este servicio!"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este acceso es utilizado por una o varias\nautorizaciones o está conectado a una central,\npor lo que no puede eliminarlo."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta autorización es utilizada por una o varias tarjetas,\npor lo que no puede eliminarla."])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta funcionalidad solo está disponible en Internet Explorer en este momento!"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Cree apartamentos para poder programar tarjetas!"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Defina previamente los parámetros de la central!"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de central ya está en uso!"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de residencia ya está en uso!"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de escalera ya está en uso!"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de apartamento ya está en uso para esta escalera!"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de autorización ya está en uso!"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de acceso ya está en uso!"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de clave Pass ya existe!"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede crear más de\n11 códigos de teclado por puerta."])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede crear más de\n10 códigos telefónicos por puerta."])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede crear más de\n1 código de teclado para esta puerta."])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede tener más de 16 accesos de tipo Lectura/Escritura para una autorización."])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 6 residencias de tipo Lectura/Escritura en esta clave Pass."])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 6 escaleras de tipo Lectura/Escritura en esta clave Pass."])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 6 accesos de tipo Lectura/Escritura en esta clave Pass."])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta central ya está en uso!"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, verifique el número de bloque o contrato ingresado.\nSi su equipo no forma parte de un paquete,\nseleccione un equipo a continuación."])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunas centrales GSM no pudieron actualizarse debido a problemas con las cuotas del plan GSM."])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Algunas centrales son de tipo Lectura/Escritura\ny requieren ser puestas en servicio!"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa funzionalità richiede l'installazione del programmatore\n(disponibile solo su Internet Explorer)!"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo accesso è utilizzato per un'autorizzazione,\nnon è possibile cambiarne il funzionamento!"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este acceso es utilizado por códigos de teclado,\n¡no puede cambiar su funcionamiento!"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Solo puede conectar accesos de tipo placa deslizante en este conector!"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No puede conectar accesos de tipo placa deslizante en este conector!"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Debe conectar este acceso a una central primero!"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este equipo solo está disponible en Francia!"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 18 residencias de tipo Lectura/Escritura en esta clave Pass."])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 18 escaleras de tipo Lectura/Escritura en esta clave Pass."])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de 18 accesos de tipo Lectura/Escritura en esta clave Pass."])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar residencias de tipo Lectura/Escritura (HF) en esta telecommande Pass."])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar escaleras de tipo Lectura/Escritura (HF) en esta telecommande Pass."])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar accesos de tipo Lectura/Escritura (HF) en esta telecommande Pass."])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile programmare più di 5 residenze di tipo Lettura/Scrittura (HF) su questa telecamera Pass."])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile programmare più di 5 scale di tipo Lettura/Scrittura (HF) su questa telecamera Pass."])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile programmare più di 5 accessi di tipo Lettura/Scrittura (HF) su questa telecamera Pass."])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede tener más de 5 accesos de tipo Lectura/Escritura (HF) para una autorización."])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Debe conectar primero esta central a un acceso!"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de serie de la central no es válido"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No puede tener más de 10 accesos de tipo LIGHT para una autorización!"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Debe usar tarjetas o mandos a distancia (S)\npara abrir este tipo de acceso o estos tipos de acceso!"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No puede tener más de 10 accesos de tipo LIGHT programados en una tarjeta!"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay más apartamentos disponibles en esta escalera!"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación completada con éxito"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No puede eliminar la tarjeta de opción!\nHay equipo conectado al conector 3 o 4."])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Su programador no es compatible con esta función!"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se produjo un error al cargar la copia de seguridad, por favor inténtelo de nuevo!"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La tarjeta insertada no es una tarjeta de copia de seguridad!"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Compruebe si la copia de seguridad de la tarjeta está correctamente inserta!"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se produjo un error al cargar la copia de seguridad, por favor inténtelo de nuevo!"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tiene una copia de seguridad conectada en el conector interno y otra en el lector de tarjetas inteligentes..."])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta Clémobil ya está registrada en esta escalera!"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Su programador no es compatible con esta función!"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se produjo un error al cargar la memoria, por favor inténtelo de nuevo!"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La tarjeta insertada no es una tarjeta de memoria!"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Compruebe si la memoria de la tarjeta está correctamente inserta!"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se produjo un error al cargar la memoria, por favor inténtelo de nuevo!"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La tarjeta insertada no es la tarjeta de memoria de esta central!"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de códigos de teclado para este acceso!"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este acceso no es conectable en este tipo de conector!"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de intentos para el servicio de SMS!"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se produjo un error al enviar el SMS, por favor inténtelo de nuevo!"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de códigos de teclado para esta central!"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este acceso solo es conectable en el conector n°1!"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este acceso no es conectable en este tipo de central o en este número de conector!"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Estos códigos son desconocidos!"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este código de producto ya está activado!"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de tarjetas para este apartamento!"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de mandos a distancia para este apartamento!"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo se permiten letras (a-z)(A-Z), números (0-9) y caracteres (.,-,_), no se permiten espacios."])},
      "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No puede crear más registros en este apartamento!"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos una residencia!"])},
      "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos una escalera!"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos una puerta!"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos un acceso!"])},
      "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos una agencia!"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta tarjeta no existe"])},
      "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número máximo de tarjetas de gestor asignables!"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario ya posee este tipo de llave"])},
      "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este registro ha sido eliminado!"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ha alcanzado el número de días festivos permitidos!"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este caso de uso está siendo utilizado en otros accesos y no puede ser eliminado!"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta semana está siendo utilizada en un horario!"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario inexistente!"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accesible!"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este identificador ya está en uso!"])},
      "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de edificio ya está en uso!"])},
      "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para procesar!"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta tarjeta no está presente!"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al iniciar la actualización, inténtelo de nuevo más tarde."])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta tarjeta no es válida o ya está en uso!"])},
      "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este código de barras no existe!"])},
      "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este código de barras ya está en uso!"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servicio de creación de cuenta no está disponible, inténtelo de nuevo más tarde."])},
      "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta cuenta no se puede trasladar."])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario ya posee este tipo de llave."])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene subcuentas todavía."])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene una memoria conectada en el conector interno y otra en el lector de tarjetas inteligentes..."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este nombre de playa ya está en uso!"])},
      "201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta playa está en uso y no se puede eliminar!"])},
      "202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos que coincidan con su búsqueda..."])},
      "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña ingresada no es válida..."])},
      "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione al menos un destinatario!"])},
      "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, ha superado su límite de datos, no puede actualizar su central..."])},
      "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, el número de teléfono GSM está vacío, no puede actualizar su central..."])},
      "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, ha superado el número máximo de teléfonos internacionales permitidos..."])},
      "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, su paquete de datos no es compatible con esta configuración de hardware..."])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, su paquete de datos no tiene autorización para realizar llamadas de video, suscríbase a la opción de video o desactive la opción de llamada de video en las fichas correspondientes..."])},
      "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede declarar este tipo de kit IntraBox Eco en una residencia básica..."])},
      "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede programar más de diez tarjetas de este tipo..."])},
      "800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este contrato GSM ya está en uso!"])},
      "801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error. Por favor, póngase en contacto con el soporte..."])},
      "802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese el número de bloque o el número de teléfono..."])},
      "803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ningún equipo asociado a este contrato..."])},
      "804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este tipo de contrato no es compatible con este tipo de residencia..."])},
      "805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para declarar este módulo, comience eligiendo su equipo (interfono, lector...) y luego \"Configurar la central\"..."])},
      "806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, su paquete de datos está suspendido, por favor póngase en contacto con INTRATONE (02 51 57 03 07)..."])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se está realizando una actualización de la pantalla... Debe esperar..."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El texto es demasiado largo, acórtele..."])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar al menos la columna \"nombre del apartamento\"..."])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una columna es para el nombre de la ficha, pero falta una columna para el teléfono principal..."])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error que interrumpió la importación..."])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El número de teléfono secundario no es válido!"])},
      "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El número de teléfono principal no es válido!"])},
      "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Un identificador no es válido!"])},
      "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Un nombre no es válido!"])},
      "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El nombre del apartamento no es válido!"])},
      "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El número de serie no es válido!"])},
      "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Falta el número de teléfono principal!"])},
      "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ingresa un identificador sin nombre de ficha!"])},
      "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ingresa un número de teléfono secundario sin nombre de ficha!"])},
      "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ingresa un número abreviado sin nombre de ficha!"])},
      "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ingresa un grupo de visualización sin nombre de ficha!"])},
      "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ingresa un número de teléfono principal sin nombre de ficha!"])},
      "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El formato de la imagen no es compatible!"])},
      "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La gestión avanzada no es posible, el usuario tiene acceso global a las residencias!"])},
      "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Un número abreviado ya está en uso!"])},
      "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El tamaño de la imagen es demasiado grande!"])},
      "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Debe seleccionar una columna para las jaulas y una columna para los nombres de los apartamentos!"])},
      "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta imagen está en uso, no se puede eliminar!"])},
      "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este tipo de archivo no es compatible!"])},
      "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al enviar los datos"])},
      "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La duración de la visualización debe ser un número positivo"])},
      "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de inicio no es válida"])},
      "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de finalización no es válida"])},
      "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de inicio debe ser anterior a la fecha de finalización"])},
      "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al guardar"])},
      "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Seleccione un archivo!"])},
      "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Parece que no se ha podido importar este archivo!"])},
      "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina el acceso en cuestión"])},
      "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de los residentes finalizada..."])},
      "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de las tarjetas y los mandos a distancia finalizada..."])},
      "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunos datos del formulario no son válidos"])},
      "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposible encontrar información sobre el apartamento"])},
      "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono con formato incorrecto"])},
      "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al recuperar el número de teléfono"])},
      "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La insignia no se puede añadir"])},
      "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al recuperar la información de la insignia"])},
      "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppa tessere di un certo tipo"])},
      "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay suficientes insignias de un determinado tipo"])},
      "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposible recuperar información sobre la programación"])},
      "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante la creación del registro"])},
      "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante la actualización del registro"])},
      "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El registro ya está asociado a un número de teléfono"])},
      "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: varios registros en el apartamento"])},
      "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante la recuperación de información de los registros"])},
      "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante la creación de la tarjeta"])},
      "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, esta acción requiere que retire la antena Connect.iT conectada a su módulo"])},
      "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Su cuenta ya es una cuenta de administrador"])},
      "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposible cambiar a una cuenta de administrador"])},
      "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposible cambiar a una cuenta de administrador"])},
      "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: El nombre de la central debe ser proporcionado"])},
      "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Problema de configuración"])},
      "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Debe proporcionar el nombre del acceso"])},
      "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Su contrato no permite agregar este equipo"])},
      "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: El equipo ya está declarado"])},
      "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Imposible identificar el contrato, complete la información"])},
      "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al solicitar la creación de la cuenta"])},
      "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde"])},
      "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Función bloqueada por su administrador"])},
      "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno de los campos proporcionados no es correcto. Por favor verifíquelo."])},
      "API_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dispone de las autorizaciones necesarias para esta acción."])},
      "PARAMS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "PARAMS_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "ACCES_TYPEFAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al solicitar la creación de un tipo de acceso."])},
      "ACCES_TYPEEXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Tipo de acceso ya declarado"])},
      "POLICY_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dispone de las autorizaciones necesarias para esta acción."])},
      "WD_0_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria de lunes con una hora de finalización posterior a la hora de inicio"])},
      "WD_1_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria del martes con una hora de finalización posterior a la hora de inicio"])},
      "WD_2_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria del miércoles con una hora de finalización posterior a la hora de inicio"])},
      "WD_3_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria del jueves con una hora de finalización posterior a la hora de inicio"])},
      "WD_4_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria del viernes con una hora de finalización posterior a la hora de inicio"])},
      "WD_5_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria de sábado con una hora de finalización posterior a la hora de inicio"])},
      "WD_0_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para el lunes"])},
      "WD_6_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una franja horaria dominical con una hora de finalización posterior a la de inicio"])},
      "WD_1_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para el martes"])},
      "WD_2_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha identificado un solapamiento entre 2 franjas horarias para los miércoles"])},
      "WD_3_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para el jueves"])},
      "WD_4_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para los viernes"])},
      "WD_5_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para el sábado"])},
      "WD_6_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un solapamiento entre 2 franjas horarias para el domingo"])},
      "WD_0_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_1_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_3_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_2_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_5_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_4_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])},
      "WD_6_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, inténtelo de nuevo más tarde."])}
    },
    "connection": {
      "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha cerrado sesión"])},
      "connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha iniciado sesión como ", _interpolate(_named("name"))])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sesión ha expirado, por favor inicie sesión nuevamente"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión imposible"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de inicio de sesión no válida"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuenta ha sido registrada"])}
    },
    "form": {
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe aceptar los términos y condiciones para crear una nueva cuenta"])},
      "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunas informaciones del formulario son incorrectas o incompletas"])},
      "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña y la confirmación de la contraseña deben ser iguales"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un país"])},
      "required_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un día"])},
      "invalid_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hora introducida no es válida"])},
      "residence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un tipo de residencia"])},
      "industryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un sector de actividad"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un departamento Vigik+"])}
    },
    "version": {
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar las mejoras"])},
      "new_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay mejoras disponibles"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tiene ", _interpolate(_named("count")), " notificación"]), _normalize(["Tiene ", _interpolate(_named("count")), " notificaciones"])])},
    "no_mobile_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta página aún no ha sido optimizada para su visualización en dispositivos móviles. Estamos trabajando en ello."])},
    "globalNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevas notificaciones"])},
    "access": {
      "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La orden de apertura se ha enviado correctamente."])}
    },
    "residence": {
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus preferencias RGPD se han guardado"])}
    }
  },
  "link_words": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])}
  },
  "input": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete este campo"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo solo debe contener números"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo no es válido"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar..."])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Debe tener ", _interpolate(_named("length")), " caracteres"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta dirección no es válida"])}
  },
  "errors": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus derechos no le permiten ver esta página"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede cargar esta página, ¡no existe!"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error interno"])},
    "oups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups!"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error, póngase en contacto con el Soporte"])}
  },
  "language": {
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir un idioma"])}
  },
  "global": {
    "entities": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residencia"])},
      "residenceType": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residencia básica"])},
        "simplified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residencia simplificada"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residencia compleja"])}
      },
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
      "stairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada"])},
      "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartamento"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de residente"])}
    },
    "status": {
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizando"])},
      "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesita actualización"])},
      "updateResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios en su casa ya son efectivos"])},
      "notInService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disponible"])},
      "inService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En uso"])},
      "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "updateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar todo"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de actualización"])},
      "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puesta en servicio"])},
      "optionPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción Pass"])}
    },
    "modal": {
      "edit-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar la franja horaria"])},
      "create-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una franja horaria"])},
      "moveTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a"])},
      "copie-day-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar horarios"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "rgpd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cumplimiento del RGPD para eventos en residencias: ", _interpolate(_named("name"))])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención !"])},
      "deviceNotCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El dispositivo que está utilizando para programar sus accesorios no es compatible con los programadores USB. Por favor, utilice un ordenador."])},
      "delete_cylinder?_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea borrar este cilindro? Esta acción es irreversible."])},
      "window_open_site_in_host_please_close_it": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya tiene una ventana abierta con el uso de un programador en la página ", _interpolate(_named("name")), ". Por favor, ciérrela para poder utilizar sus programadores en esta página."])},
      "device_using_program_accessories_not_compatible_USB_programmers_use_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El dispositivo que está utilizando para programar sus accesorios no es compatible con los programadores USB. Por favor, utilice un ordenador."])},
      "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas actualizar la versión de tu programador para poder añadir o actualizar accesorios."])},
      "valid": {
        "week-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar las nuevas franjas horarias"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres cancelar los cambios?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe validar los cambios"])},
        "access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme el nuevo tipo de acceso"])},
        "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea eliminar este tipo de acceso?"])},
        "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
        "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la residencia"])},
        "politicsUpdateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que cualquier personalización que haya realizado previamente se eliminará si valida esta actualización. ¿Desea utilizarla?"])},
        "politicsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik modificación pendiente"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la configuración predeterminada"])}
      },
      "liberal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de profesionales autónomos"])},
      "vigik": {
        "not-enough-time-slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el número máximo de franjas horarias. Por favor, elimine algunas para crear otras nuevas."])}
      },
      "providersStatus": {
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resumen de cambios (", _interpolate(_named("count")), ")"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizados"])},
        "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No autorizada"])}
      },
      "doubleAuth": {
        "error": {
          "attempt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["El código de validación es incorrecto. Le quedan ", _interpolate(_named("count")), " intentos"]), _normalize(["El código de validación es incorrecto. Le quedan ", _interpolate(_named("count")), " intentos"])])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita el procedimiento. En 10 segundos se le redirigirá a la página de inicio de sesión."])},
          "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código ya no es válido."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble autenticación"])},
        "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de validación"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado un código de validación único a esta dirección de correo electrónico:"])}
      }
    },
    "array": {
      "noSearchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún dato corresponde a su búsqueda"])},
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista vacía"])}
    }
  },
  "weekType": {
    "weekday": {
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])}
    },
    "copie-choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿En qué días desea copiar el horario ", _interpolate(_named("day")), "?"])},
    "max-different-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el número máximo de días diferentes en el calendario. ¿Desea reutilizar el horario de otro día?"])}
  },
  "vigik": {
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del tipo de acceso"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación de copia"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea cancelar la creación de un nuevo tipo de acceso?"])},
      "create-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiere crear este nuevo tipo de acceso?"])},
      "openingPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridad de apertura"])},
      "obligatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio"])},
      "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
      "minimalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La franja horaria debe ser superior a 15 minutos"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrado"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios Vigik+"])},
    "modale": {
      "createSchedule": {
        "limitAtFiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede crear más de cinco franjas horarias al día."])},
        "existingSchedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya hay una franja horaria a estas horas"])}
      },
      "copySchedule": {
        "onNextDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los días siguientes"])},
        "copyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie el"])}
      }
    },
    "global": {
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
      "bannedProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores de servicios prohibidos"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores de servicios personalizados"])},
      "whitelisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Personalizado"]), _normalize([_interpolate(_named("count")), " personalizado"]), _normalize([_interpolate(_named("count")), " personalizados"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De fábrica"])},
      "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Prohibido"]), _normalize([_interpolate(_named("count")), " prohibido"]), _normalize([_interpolate(_named("count")), " prohibidos"])])},
      "activitiesAllows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad autorizada"])},
      "activitiesBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad no autorizada"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
      "allAutorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los autorizados"])}
    },
    "navigation": {
      "listPersonalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de actividades"])},
      "listAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de tipos de acceso"])},
      "list-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de proveedores de servicios"])},
      "searchCharacterLimiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene al menos 3 caracteres"])},
      "list-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de actividades"])}
    },
    "config": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al crear un lector compatible con Vigik+, debe elegir el tipo de lector"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite definir el perfil Vigik que se utilizará"])},
      "accessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de acceso"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "accessNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de accesos"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
      "createAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un tipo de acceso"])},
      "confirmPoliticsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya está disponible una nueva póliza Vigik. ¿Quiere utilizarla?"])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar el tipo de acceso"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir las actividades de los proveedores de servicios en esta página"])},
      "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la actividad"])},
      "providerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del proveedor de servicios"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizado"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores de servicios con excepciones"])},
      "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No autorizada"])},
      "reset-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer los tiempos por defecto"])},
      "reset-to-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer los tiempos por defecto"])},
      "reset-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer los tipos de acceso por defecto"])},
      "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea restablecer la configuración predeterminada?"])},
      "activityStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la actividad"])},
      "activityTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario de actividades"])},
      "providerTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario del proveedor de servicios"])},
      "timetablesCustomised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horarios personalizados"])},
      "timetablesDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario por defecto"])}
    },
    "schedule": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleccione las horas deseadas para ", _interpolate(_named("name"))])},
      "edit-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres guardar los cambios?"])},
      "update-schedule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El horario de ", _interpolate(_named("name")), " ha sido modificado."])},
      "edit-schedule-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desea anular los cambios?"])},
      "reset-schedule-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, cambie el horario para que se pueda reajustar."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario"])},
      "selectAllDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione todo el día"])}
    },
    "activity": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una actividad o un proveedor de servicios"])},
      "modification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " modificación"]), _normalize([_interpolate(_named("count")), " modificaciones"])])}
    },
    "provider": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Proveedores de servicios autorizados para ", _interpolate(_named("name"))])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del proveedor de servicios"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar un proveedor de servicios"])},
      "searchCustomProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un proveedor de servicios personalizado"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un proveedor de servicios"])},
      "searchForbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un proveedor de servicios para prohibir"])},
      "forbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir un proveedor de servicios"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lista de accesos de tipo ", _interpolate(_named("name"))])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de el edificio"])},
      "alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usted tiene ", _interpolate(_named("count")), " acceso fuera de su gestión"]), _normalize(["Usted tiene ", _interpolate(_named("count")), " acceso fuera de su gestión"]), _normalize(["Usted tiene ", _interpolate(_named("count")), " accesos fuera de su gestión"])])},
      "cage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
      "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea eliminar este tipo de acceso?"])},
      "update-access-check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Los accesos ", _interpolate(_named("name")), " han sido modificados."])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que cualquier personalización que haya realizado previamente se eliminará si confirma esta restauración. Desea restaurar la configuración predeterminada?"])},
      "create-access-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ha creado el tipo de acceso ", _interpolate(_named("name")), "."])}
    }
  },
  "exportFobs": {
    "fobsNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 dispositivo"]), _normalize(["1 dispositivo"]), _normalize([_interpolate(_named("cuenta")), " dispositivos"])])},
    "fobsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de dispositivos"])},
    "fobsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minCount")), " a ", _interpolate(_named("maxCount"))])},
    "fobsSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N.° de credencials"])},
    "fobsLastUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última fecha de uso"])},
    "fobsFilterOneWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 1 semana"])},
    "fobsFilterTwoWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 2 semanas"])},
    "fobsFilterThreeMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 3 meses"])},
    "fobsFilterDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Elija un periodo -"])},
    "fobsFilterOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 1 mes"])},
    "fobsFilterOneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 1 año"])},
    "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún dato corresponde a su búsqueda"])},
    "fobsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar esta insignia? Esta acción es irreversible"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un dispositivo o un piso"])},
    "fobsFilterSixMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 6 meses"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de dispositivo vacíos"])}
  },
  "publicHome": {
    "residenceTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir una residencia"])},
    "manuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogos e instrucciones"])},
    "returnCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redactar una nota de devolución"])},
    "2gInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre la red 2G"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder al soporte técnico"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar los términos generales"])},
    "programmerSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar el programador"])}
  },
  "navigation": {
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis residencias"])},
    "bulletinBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablón de anuncios"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claves Pass"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horarios de apertura"])},
    "manageSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar / buscar"])},
    "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios Vigik"])},
    "texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío de SMS"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras otras herramientas"])},
    "propass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProPass"])},
    "programmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programación"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
    "keySafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caja de llaves"])},
    "programmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programación 2"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayudas"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])},
    "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papelera"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "residenceBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la residencia"])},
    "listBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a mis sitios"])},
    "buildingBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al edificio"])},
    "old_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión anterior del sitio"])},
    "stairwellBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la jaula"])},
    "vigik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios Vigik+"])},
    "errors": {
      "notAccessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no accesible a través de esta ruta."])}
    }
  },
  "login": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre de usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña"])},
    "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconexión"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé mi contraseña"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tengo una cuenta aún"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a su espacio de gestión"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intratone ofrece una gama de control de acceso e intercomunicadores para viviendas colectivas. Un concepto conectado y a la vez innovador."])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "idSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su nombre de usuario completo será: ", _interpolate(_named("username")), "."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre de usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme su contraseña"])},
    "passwordRule5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 carácter especial"])},
    "passwordRules1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tener una longitud mínima de 8 caracteres"])},
    "passwordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña debe:"])},
    "passwordRules3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una letra minúscula"])},
    "passwordRules2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cumplir con 3 de estas condiciones:"])},
    "passwordRules5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un número"])},
    "passwordRules4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una letra mayúscula"])},
    "passwordRules6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un carácter especial"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su apellido"])},
    "doubleAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active la doble autenticación. Cada vez que se conecte, se le enviará un código de un solo uso a la dirección de correo electrónico que haya introducido. Esta opción puede desactivarse en cualquier momento."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su dirección"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su ciudad"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su código postal"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su país"])},
    "chooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu país"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su empresa"])},
    "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sector de actividad"])},
    "chooseIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu sector de actividad"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su número de teléfono"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar los"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos de uso"])},
    "alertSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras (a-z), números (0-9) y caracteres (-, _), los espacios no están permitidos."])},
    "anssiPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acuerdo con las recomendaciones del CCN, su contraseña debe incluir al menos :"])},
    "passwordRule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 caracteres"])},
    "passwordRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mayúscula"])},
    "passwordRule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 número"])},
    "passwordRule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minúscula"])}
  },
  "forgottenPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ha olvidado su contraseña?"])},
    "giveMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la dirección de correo electrónico de su cuenta y le enviaremos instrucciones para renovar su contraseña."])},
    "mailOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail o login"])},
    "error": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las dos contraseñas son diferentes."])},
      "codeProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe el código"])},
      "notRespect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña no cumple las recomendaciones del CCN."])}
    },
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme su nueva contraseña"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña ha sido modificada. Será redirigido a la página de inicio de sesión en 10 segundos."])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software de asistencia con soporte técnico"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use estos enlaces para permitir el control remoto por un técnico de soporte durante una llamada telefónica."])}
  },
  "residencesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis residencias"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un sitio"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " sitio"]), _normalize([_interpolate(_named("count")), " sitio"]), _normalize([_interpolate(_named("count")), " siti (", _interpolate(_named("first")), " a ", _interpolate(_named("last")), ")"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una residencia"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar una residencia"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista"])},
    "caption": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor por defecto"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor personalizado"])}
    },
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la actualización"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])}
    },
    "form": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de residencias"])},
      "types": {
        "title-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completo"])},
        "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplificada"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básica"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su residencia se compone de varios residenciales o de varias escaleras"])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiere gestionar un aparcamiento solo con receptores HF"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su residencia se compone de una única escalera que lleva a varios apartamentos"])}
      },
      "residence-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de el edificio"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Postal"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento Vigik+"])},
      "vigik-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, consulte el Departamento de Vigik+ introducido"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "agence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencia"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres borrar tu residencia?"])},
      "locative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa la gestión de alquiler"])},
      "liberal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión profesión liberal"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos RGPD"])},
      "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la residencia"])},
      "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "residenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de residencia"])},
      "advancedOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones avanzadas"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
      "autorisation": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización predeterminada"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización por defecto (mando a distancia)"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización por defecto (Llaves-Móviles)"])},
        "ipass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residencia gestionada con iPass"])}
      }
    },
    "modal": {
      "rgpd": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentes"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terciario"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usted es arrendador o comunidad de propietarios: El control de acceso lo utilizan los inquilinos o los propietarios. Los eventos serán anónimos."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usted es una empresa privada o pública: El control de acceso lo utilizan empleados de mi empresa. Los eventos serán visibles durante 3 meses. Utilizar este estatuto para residentes está sancionado por ley."])},
        "detail-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El control de acceso lo utilizan proveedores (empresas de limpieza, jardinería, etc.) externos a la plantilla de mi empresa. Los eventos serán visibles 3 días. Utilizar este estatuto para residentes está sancionado por ley."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de seguridad"])},
        "cage-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del bloque"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "entityDetails": {
    "tabs": {
      "buildingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del edificio"])},
      "residenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la residencia"])},
      "apartmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del apartamento"])},
      "stairwellDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la jaula"])},
      "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos"])},
      "authorisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizaciones"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de alquiler"])},
      "centralUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales"])},
      "liberale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesión liberal"])},
      "intercoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrado en placas"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcuentas"])},
      "connectit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gama Connect.it"])}
    },
    "actions": {
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
      "manualImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación manual"])},
      "stairwellExportA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vivienda - Badge(s) - Número de badges - Nombre(s) - Identificador - Nombre - Firma"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactar con el servicio técnico"])},
      "stairwellExportB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vivienda - Badge(s) - Número de badges - Nombre(s) - Identificador - Teléfono P - Teléfono S - Llamada - Grupo"])},
      "stairwellExportC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de asistencia"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar"])},
      "assistedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación asistida"])}
    },
    "externalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso fuera del edificio"])},
    "internalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso y equipos"])},
    "accessories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ningún accesorio"]), _normalize([_interpolate(_named("count")), " accesorio"]), _normalize([_interpolate(_named("count")), " accesorios"])])},
    "resident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ningún inquilino"]), _normalize([_interpolate(_named("count")), " inquilinos"]), _normalize([_interpolate(_named("count")), " inquilinos"])])},
    "floor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Planta baja"]), _normalize([_interpolate(_named("count")), " 1ª planta"]), _normalize([_interpolate(_named("count")), " 3ª planta"])])},
    "basement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Planta baja"]), _normalize([_interpolate(_named("count")), " primer piso"]), _normalize([_interpolate(_named("count")), " tercer piso"])])}
  },
  "accessCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un acceso"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos"])},
    "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos"])},
    "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apertura del acceso"])},
    "badges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tarjetas / Controles remotos (", _interpolate(_named("count")), ")"])}
  },
  "apartmentsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apartamentos, casas, villas (", _interpolate(_named("count")), ")"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un apartamento"])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
    "createOrEdit": {
      "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piso"])},
      "letterBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buzón"])},
      "liberalGestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar la profesión liberal"])},
      "floorOptions": {
        "subfloor_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trastero n°1"])},
        "subfloor_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trastero n°2"])},
        "subfloor_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trastero n°3"])},
        "floor_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planta baja"])},
        "floor_?": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Piso n°", _interpolate(_named("count"))])}
      },
      "boxOptions": {
        "box_?": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["n°", _interpolate(_named("count"))])},
        "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguna"])}
      },
      "typeOptions": {
        "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún"])},
        "type_?": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hab."])}
      },
      "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar"])},
      "changeDisplayHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar las horas de visualización"])},
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres deshacerte de este piso?"])},
      "liberalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del residente sólo se mostrará cuando una placa pase por el piso a una hora determinada (por defecto de 6 a 21 horas). Este modo requiere una unidad de control de 2 puertas [03-0101] con un número de serie superior a 00020001."])}
    },
    "arrayColumns": {
      "firstResident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera ficha"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta(s)"])},
      "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residente(s)"])}
    },
    "noAuthorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está autorizado a consultar los appartamentos."])}
  },
  "intercomsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mostrado en placas (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])}
    }
  },
  "codesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos por entrada"])},
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " acceso"])},
    "keypadsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos por acceso"])},
    "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos telefónicos"])}
  },
  "centralUnitsList": {
    "realTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo real"])},
    "readWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectura / escritura"])},
    "searchCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una unidad central"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de serie"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de teléfono"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la actualización"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])}
    },
    "lora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible Connect-It"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " centrales"]), _normalize([_interpolate(_named("count")), " centrales"]), _normalize([_interpolate(_named("count")), " centrales (de ", _interpolate(_named("first")), " a ", _interpolate(_named("last")), ")"])])}
  },
  "keyCard": {
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una tarjeta"])},
    "teleco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control remoto"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clémobil"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Seguro que desea eliminar la tarjeta ", _interpolate(_named("serial")), "?"])},
    "serial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["nº", _interpolate(_named("serial"))])},
    "kibolt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave Kibolt"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta clave debe cargarse"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta llave está declara como perdida"])}
    },
    "copie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta copiada"])},
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negro"])},
      "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verde"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azul"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rojo"])},
      "brown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marrón"])},
      "grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amarillo"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naranja"])},
      "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morado"])},
      "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanco"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No especificado"])}
    }
  },
  "residenceCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una residencia"])}
  },
  "residentCard": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
    "mainNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número principal"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una ficha"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sideNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número secundario"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Seguro que deseas eliminar al residente ", _interpolate(_named("nom")), "?"])}
  },
  "stairwellCard": {
    "createBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un edificio"])},
    "accesses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accesos (", _interpolate(_named("count")), ")"])},
    "apartments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " appartamento"]), _normalize([_interpolate(_named("count")), " appartamento"])])},
    "stairwells": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " bloque"]), _normalize([_interpolate(_named("count")), " bloques"])])},
    "createStairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una jaula"])}
  },
  "subaccountsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subcuentas (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "fisrtName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])}
    }
  },
  "agencesList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agencias (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])}
    }
  },
  "text": {
    "userDataLaws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted tiene derecho de acceso, modificación, rectificación y eliminación de sus datos personales (art. 34 de la Ley de Informática y Libertades). Para ejercerlo, diríjase a"])},
    "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico :"])},
    "emailIntratoneSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.uk.com"])},
    "linkEmailIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["técnico"])},
    "linkEmailDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.uk.com"])},
    "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "contactCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "brands": {
    "windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple"])}
  },
  "bloc_erp": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "visio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada de video"])},
    "appPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["necesario para videollamada en PC"])},
    "telsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono secundario"])},
    "visiosec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada de video en teléfono secundario"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])}
  },
  "redirect": {
    "https": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirección al sitio en HTTPS"])},
    "http": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirección al sitio en HTTP"])}
  },
  "MCI": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta Intratone"])},
    "select": {
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy gestor"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy residente"])}
    },
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a su cuenta de residente, haga clic en el siguiente botón."])}
  },
  "module": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulos a sustituir"])},
    "excelExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar a Excel"])},
    "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remplazar"])},
    "table": {
      "centraleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central"])},
      "serialModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie del módulo"])},
      "portaconnectCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia compatible con Portaconnect"])},
      "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia de sustitución"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustituir el módulo"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una residencia o el número de serie de un módulo"])},
      "portaconnectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se trata de una puerta, puede aprovechar la oferta Portaconnect. Haga clic <a href=\"https://portaconnect.intratone.fr/\">aquí</a> para más detalles."])},
      "finishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre de la 2G"])}
    },
    "toReplace": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo módulo"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulo a sustituir"])},
      "centrale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la unidad central"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono actual"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustitución del módulo"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo número de teléfono"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar Clémobil (Llave móvil)"])}
    },
    "error": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención !"])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para validar la sustitución, descargue la lista de los domicilios Clémobil (Llave móvil) afectados por el cambio de número de teléfono y notifíquelo a los residentes afectados."])},
      "notCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este módulo no es compatible con los equipos existentes."])},
      "noDataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de módulos a sustituir vacía."])},
      "isNotFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si abandona la página sin completar la sustitución, no se tendrá en cuenta. Desea abandonar la página?"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún dato corresponde a su búsqueda."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se tiene en cuenta la sustitución del módulo ", _interpolate(_named("oldName")), " por el módulo ", _interpolate(_named("newName")), "."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar la sustitución"])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este módulo se actualizará en cuanto se encienda. En ese momento se sustituirá en el sitio de gestión."])}
    }
  },
  "date": {
    "february": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " febrero ", _interpolate(_named("year"))])},
    "january": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " enero ", _interpolate(_named("year"))])},
    "march": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " marzo ", _interpolate(_named("year"))])},
    "april": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " abril ", _interpolate(_named("year"))])},
    "may": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " mayo ", _interpolate(_named("year"))])},
    "june": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " junio ", _interpolate(_named("year"))])},
    "september": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " septiembre ", _interpolate(_named("year"))])},
    "july": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " julio ", _interpolate(_named("year"))])},
    "august": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " agosto ", _interpolate(_named("year"))])},
    "october": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " octubre ", _interpolate(_named("year"))])},
    "november": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " noviembre ", _interpolate(_named("year"))])},
    "december": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " diciembre ", _interpolate(_named("year"))])}
  },
  "kibolt": {
    "connected_key_programmer_but_nothing_happens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has conectado la llave al programador, pero no se ha producido ningún cambio?"])},
    "connected_cylinder_programmer_but_nothing_happen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has conectado tu cilindro al programador, pero no se ha producido ningún cambio?"])},
    "plug_in_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte su programador"])},
    "key_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La llave ha sido borrada"])},
    "key_is_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La llave está actualizada"])},
    "delete_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al borrar. Inténtelo de nuevo."])},
    "update_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La actualización ha fallado. Por favor, inténtelo de nuevo."])},
    "currently_being_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso de eliminación"])},
    "currently_updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso de actualización"])},
    "then_wait_few_seconds_be_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...y esperar unos segundos a que la aplicación lo detecte."])},
    "your_programmer_not_responding_even_though_it_is_plugged_in?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tu programador no responde a pesar de estar enchufado?"])},
    "plug_then_wait_seconds_to_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conéctalo y espera unos segundos a que la aplicación lo detecte."])},
    "replace_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar el cilindro"])},
    "move_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover el cilindro"])},
    "remove_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar cilindro"])},
    "size_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del cilindro"])},
    "cylinder_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilindro detectado"])},
    "insert_replacement_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserte el cilindro de recambio"])},
    "insert_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserte la llave"])},
    "wait_seconds_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…espere unos segundos a que la aplicación lo detecte."])},
    "keys_still_open_until_updated_or_barrel_is_re_declared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las llaves siempre pueden abrir hasta que sean actualizadas o hasta que el cilindro sea redefinido."])},
    "replacement_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplazo en curso."])},
    "unplug_kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No desenchufe el Kiprog mientras se esté actualizando."])},
    "insert_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserte el cilindro"])},
    "can_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puede retirar el Kiprog del cilindro."])},
    "replacement_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustitución finalizada."])},
    "requires_administrator_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requieren derechos de administrador"])},
    "be_careful_cylinder_not_functional_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, el cilindro no funciona. Debe intentarlo de nuevo."])},
    "kibolt_key_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programador de llaves Kibolt"])},
    "error_occurred_during_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante la actualización."])},
    "unknown_error_contact_customer_or_try_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error desconocido. Póngase en contacto con el servicio de atención al cliente o inténtelo más tarde."])},
    "cylinder_already_exists_insert_another_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cilindro en el programador no existe o ya está en uso. Por favor, inserte otro cilindro."])},
    "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas actualizar la versión de tu programador para poder añadir o actualizar accesorios."])},
    "name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la puerta"])},
    "type_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cilindro"])},
    "cylinder_not_correspond_cylinder_updated_insert_correct_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cilindro en el programador no coincide con el cilindro a actualizar. Por favor, inserte el cilindro correcto."])},
    "version_boot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión Boot"])},
    "this_is_name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es el nombre de la puerta"])},
    "version_soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión Soft"])},
    "kibolt_cylinder_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del cilindro Kibolt"])},
    "kibolt_cylinder_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustitución del cilindro Kibolt"])},
    "simple_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilindro de una puerta"])},
    "double_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilindro  de doble puerta"])},
    "button_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilindro  de puerta tipo pomo"])},
    "updates_completed_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización completada. Puede retirar el Kiprog del cilindro."])},
    "update_progress_not_unplug_Kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización en curso. No desenchufe el Kiprog mientras la actualización esté en curso."])},
    "remote_control_and_badge_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un programador de mandos a distancia e llaves electrónicas"])}
  },
  "TO_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización"])},
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["información"])},
  "TECHNICAL_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficha técnica"])},
  "CLICK_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí"])},
  "UPDATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización"])},
  "TRY_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo de nuevo"])},
  "REPLACEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustitución"])},
  "CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
  "NOT_CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conectado"])},
  "INSTALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "SERIAL_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie"])},
  "VERSION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de versión"])},
  "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "SHOW_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "SERIAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie"])},
  "ADVANCED_OPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones avanzadas"])},
  "KIBOLT_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso Kibolt"])},
  "MOVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a"])},
  "app_translate_export_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.20.0"])},
  "app_translate_export_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2025-03-03 14:52:08"])}
}